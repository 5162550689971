<template>
  <div class="p-4 bg-white rounded-lg">
    <ReportCharts
      :urls="urls"
      :mandatory-filter="[
        {
          name: 'report_date_month_range',
          title: 'Date range',
          valueFieldName: '.select',
        },
      ]"
    ></ReportCharts>
  </div>
</template>

<script>
import ReportCharts from "@/own/components/reports/ReportCharts.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "CumulativeCustomersWonPerMonth",
  components: { ReportCharts },
  data: () => ({
    urls: {
      data_url: "/reports/reportSalesCumulativeCustomerWonPerMonth",
      show_url: "/reports/reportSalesCumulativeCustomerWonPerMonth/show",
    },
  }),

  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
};
</script>
